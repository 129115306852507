
<script lang="ts">
    export interface IProps {
        mReverseOrder?: boolean;
        title?: string | string[];
        markTitle?: boolean;
    };
</script>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { useAsyncComponent } from "o365-vue-utils";
    import { isMobile } from "o365.GlobalState.ts";
    import { context } from "o365-modules";

    const MActionSheet = useAsyncComponent("o365-mobile/MActionSheet");

    const props = defineProps<IProps>();

    const titles = computed(() => (props.title instanceof Array) ? props.title : [props.title]);

    const target = ref<HTMLElement>();
    const showActionSheet = ref<boolean>(false);

    watch(target, () => {
        if (target.value) {
            if (isMobile.value) {
                target.value.onclick= () => {
                    showActionSheet.value = true;
                }
            } else {
                target.value.dataset.bsToggle = "dropdown";
            }
        }
    });
</script>


<template>
    <template v-if="isMobile">
        <slot name="target" :target="(el) => target = el" />
        <MActionSheet :title="title" v-model="showActionSheet" :reverse="isMobile && mReverseOrder">
            <slot />
        </MActionSheet>
    </template>
    <template v-else>
        <div class="dropdown">
            <slot name="target" :target="(el) => target = el" />
            <ul class="dropdown-menu">
                <template v-if="title">
                    <li>
                        <div class="dropdown-header" :class="{ 'mark': markTitle }">
                            <template v-for="title in titles">
                                <div>{{ title }}</div>
                            </template>
                        </div>
                    </li>
                </template>
                <slot />
            </ul>
        </div>
    </template>
</template>
